import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Observable, ReplaySubject} from 'rxjs';
import {Category} from '../interfaces/category';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private fetched: boolean;
  private value$: ReplaySubject<any> = new ReplaySubject();

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore) {

  }

  getAll(): Observable<Category[]> {
    if (!this.fetched) {
      this.db.colWithIds$<Category>('categories', ref => ref
        .where('trash', '==', false)
        .orderBy('position', 'asc',))
        .pipe(
          map(mem => mem.filter(item => item.key != 'yDAA6qSxC4ijauVgf9i7')))
        .subscribe(data => {
          this.fetched = true;
          this.value$.next(data);
        });
    }

    return this.value$;
  }

  add(category: Category): void {
    this.afs.collection(`categories`).add(category);
  }

  update(categoryKey: string, category: Category): Promise<void> {
    return this.afs.doc(`categories/${categoryKey}`).update(category);
  }

  get(categoryKey: string): Observable<Category> {
    return this.db.doc$<Category>(`categories/${categoryKey}`);
  }

  getReference(categoryKey: string): DocumentReference {
    return this.db.doc(`categories/${categoryKey}`).ref;
  }

  delete(categoryKey: string): void {
    this.afs.doc(`categories/${categoryKey}`).update({trash: true});
  }

  getAllTools(): any {
    return this.db.colWithIds$('tools', ref => ref.where('trash', '==', false));
  }
}
