import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/storage';
import {UserService} from '../../shared/services/user.service';
import {Membership} from '../../shared/interfaces/membership';
import firebase from 'firebase';
import User = firebase.User;
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentHistoryService {

  constructor(private afs: AngularFirestore,
              private db: FirebaseDataService,
              private storage: AngularFireStorage) {
  }

  getLatest(): Observable<any[]> {
    return this.afs.collectionGroup<any>(`history`, ref => ref
      .orderBy('createdAt', 'desc').limit(5)).snapshotChanges().pipe(map(actions => actions.map(a => {
      return {
        ...a.payload.doc.data(),
        key: a.payload.doc.id,
        userKey: a.payload.doc.ref.parent.parent.id
      };
    })));
  }

  getAll(userKey: string): Observable<any[]> {
    return this.db.colWithIds$<any>(`users/${userKey}/history`, ref => ref
      .where('trash', '==', false)
      .orderBy('createdAt', 'desc'));
  }

  get(userKey: string, membershipKey: string) {
    return this.afs.doc(`users/${userKey}/history/${membershipKey}`).valueChanges();
  }

  async add(userKey: any, membership: any): Promise<any> {
    return await this.afs.collection<any>(`users/${userKey}/history`).add(membership);
  }

  async delete(userKey: string, membershipKey: string): Promise<void> {
    return await this.afs.doc<any>(`users/${userKey}/history/${membershipKey}`).update({trash: true});
  }

  update(userKey: any, membershipKey: any, membership: any) {
    this.afs.doc(`users/${userKey}/history/${membershipKey}`).update(membership);
  }

  updatePayment(membershipKey: any, membership: Membership): Promise<void> {
    return this.afs.doc(`users/${UserService.uid}/history/${membershipKey}`).update(membership);
  }

  uploadTicket(captureDataUrl: string, orderKey: string): Promise<string> {
    return new Promise(resolve => {
      let imageRef = this.storage.ref(`users/${UserService.uid}/history/${orderKey}/receiptOfPayment.jpeg`);
      imageRef.putString(captureDataUrl, 'data_url')
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then(imageUrl => resolve(imageUrl));
        })
        .catch(err => console.log(err));
    });
  }
}
