import {Component, Input, OnInit} from '@angular/core';
import {Achievement} from '../../interfaces/achievement';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-completed-goal',
  templateUrl: './completed-goal.component.html',
  styleUrls: ['./completed-goal.component.scss', '../my-achievements/my-achievements.component.scss'],
})
export class CompletedGoalComponent implements OnInit {
  @Input() goal: string;
  @Input() achievement: Achievement;

  constructor(public modalController: ModalController) {
  }

  ngOnInit() {
  }

}
